export const toTitleCase = (str) => {
  if (str && typeof str === 'string') {
    return str
      .toLowerCase()
      .split(' ')
      .map((e) => {
        const capital = e.split('')[0].toUpperCase();
        return capital + e.slice(1, e.length);
      })
      .join(' ');
  }
  return str;
};
