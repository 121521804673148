import React from 'react';
import PropTypes from 'prop-types';

import { Avatar, Box, Container, Typography } from '@material-ui/core';
import {
  ArrowBack,
  Language,
  Phone,
  PhoneOutlined,
  RoomOutlined,
} from '@material-ui/icons';

import { Button } from 'gatsby-theme-material-ui';
import IconInfo from '../ui/IconInfo';
import Layout from '../components/layout/layout';
import PageHeader from '../ui/PageHeader';
import SEO from '../components/layout/seo';
import { toTitleCase } from '../utils/toTitleCase';

const CompanyTemplate = ({
  pageContext: {
    companyLogo,
    companyCategory,
    companyName,
    companyAddress,
    companyTel1,
    companyTel2,
    companyWebsite,
    companySlug,
  },
}) => (
  <Box>
    <SEO title={`Alphanorth | ${companyName} está aqui!`} />
    <Container>
      <PageHeader
        title={companyName}
        logo={companyLogo}
        category={companyCategory}
      />
      <IconInfo icon={<RoomOutlined />} text={toTitleCase(companyAddress)} />
      {companyTel1 && <IconInfo icon={<PhoneOutlined />} text={companyTel1} />}
      {companyTel2 && <IconInfo icon={<PhoneOutlined />} text={companyTel2} />}
      {companyWebsite && (
        <IconInfo
          icon={<Language />}
          text={companyWebsite.toLowerCase()}
          link={companyWebsite}
        />
      )}
      <br />
      <Button
        to="/empresas"
        variant="outlined"
        size="large"
        startIcon={<ArrowBack />}
      >
        Voltar à lista de empresas
      </Button>
    </Container>
  </Box>
);

CompanyTemplate.propTypes = {
  pageContext: PropTypes.object,
};

export default CompanyTemplate;
